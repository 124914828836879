@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

.footer {
  position: relative;
  bottom: 0; /* Place the footer at the bottom */
  left: 50%; /* Align the left edge of the footer to the center */
  transform: translateX(-50%); /* Adjust for centering using CSS transform */
  text-align: center; /* Center the text inside the footer */
  width: 100%; /* Ensure the footer spans the full width */
  font-weight: 800;
}
.logo {
  /* border: 1px solid red; */
  width: 410px;
  padding-bottom: 16px;
  margin-bottom: 50px;
}
body {
  margin: 0 auto;
}

.line-height {
  line-height: 2;
}
#logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  padding-top: 10vh;
  /* padding-bottom: 0vh; */
}
.custom-rounded {
  border-radius: 30px; /* Customize the border radius as needed */
  border: 1px solid #373737;
}
.chat-rounded {
  border-radius: 40px; /* Customize the border radius as needed */
  border: none;
  background-color: #e2e3e5;
  color: #212529;
}

.custom-refresh-btn {
  background-color: transparent;
  border: none;
  font-size: 1rem; /* Adjust size as needed */
  color: #000; /* Adjust color as needed */
  text-decoration: none;
  text-align: left;
}

.new_question {
  /* background-color: #b9b9b9;
  border: 0; */
}

.row {
  justify-content: center; /* Center the row's contents horizontally */
}

.credentials {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 410px;
  font-weight: 600;
}
.credentials input {
  font-family: 'Gothic A1', sans-serif;
  font-weight: 500;
  font-size: 0.9em;
}
.custom-button {
  border-radius: 30px; /* Match the border radius with input fields */
  border: 2px solid #365dd4 !important; /* Make the button border blue */
  color: #365dd4; /* Customize the text color if needed */
  font-weight: 500;
}
html,
body {
  font-family: 'NanumSquare', sans-serif;
  font-weight: 500;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* to keep footer at bottom */
.line-height {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.sidebar {
  transition: width 0.3s;
  width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure the sidebar spans the full height */
}
/* Sidebar Navigation */
#sidebar-nav {
  flex-grow: 1; /* Allow the nav to grow and take up available space */
  overflow-y: auto; /* Enable scrolling if the content exceeds the height */
  padding-bottom: 60px;
  height: 85%;
}
/* Logout */
.logout {
  padding-left: 5px;
}

.documentHandler {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.sidebar.collapsed {
  width: 0; /* Collapsed width */
  overflow: hidden;
}

.main-content {
  transition: margin-left;
  margin-left: 15px; /* When sidebar is collapsed */
}

.main-content.content-expanded {
  margin-left: 250px; /* When sidebar is expanded */
  margin-top: 30px;
}
.offcanvas {
  width: 250px; /* Adjust sidebar width */
  transition: transform 0.3s ease;
}
/* Expanded sidebar */
.offcanvas.show {
  transform: translateX(0); /* Move sidebar into view */
}
.offcanvas-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.offcanvas-header .btn {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .main-content.content-expanded {
    margin-left: 0; /* Adjust for smaller screens when sidebar is expanded */
    padding-left: 15px; /* Reset padding for smaller screens */
  }

  .offcanvas {
    width: 100%; /* Sidebar takes full width on smaller screens */
  }
}
.accordion-button {
  background-color: #e2e3e5;
  color: #495057;
}

.accordion-button:not(.collapsed) {
  background-color: #e2e3e5;
  color: #495057;
}

.list-group-item {
  white-space: nowrap; /* Prevent line break for list items */
} /* Menu Button */
.menu-button {
  display: block;
  position: fixed;
  top: 5px;
  left: 25px;
  z-index: 1000; /* Ensure it's above other elements */
}
.menu-button.hide {
  display: none;
}
.accordion-button::after {
  display: none;
}
.accordion-body {
  padding: 15px 0px 0px 15px;
}
.list-unstyled a {
  color: black;
  text-decoration: none;
}
.list-unstyled a:hover {
  color: #0d6efd;
  text-decoration: none;
}
tbody a {
  color: black;
  text-decoration: none;
}
tbody a:hover {
  color: #0d6efd;
  text-decoration: none;
}
.btn-link {
  color: #0d6efd;

  text-decoration: none;
}

/* styling main answer box */
.main-answer {
  background-color: #fff;
  padding: 32px;
  padding-left: 10%;
  padding-right: 10%;
  border-radius: 10px;
  /* border: 2px solid #a1b2bde8; */
  border: none;
}
.box {
  --b: 2px; /* thickness of the border */
  --c: #65686d; /* color of the border */
  --w: 32px; /* width of border */

  position: relative;
}
.box::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--c);
  --_g: #0000 90deg, #000 0;
  --_p: var(--w) var(--w) no-repeat;
  --mask: conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0
      0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 /
      var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% /
      var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
      100% 100% / var(--_p);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  pointer-events: none; /* 가상 요소에 마우스 이벤트가 전달되지 않도록 설정 */
}

/* styling 관련성 높은 문서 table*/
.card-answer {
  background-color: #e9ecef;
  border: none;
}
.table {
  --bs-table-bg: #e9ecef;
  --bs-table-border-color: #adb5bd;
}
.documents {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* 질문 입력 칸 */
/* styling new question textarea */
.form-control {
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: #343a40;
  color: #fff;
  padding: 16px 0px 16px 24px;
  border-radius: 2em;
  overflow: hidden; /* Prevent the default scrollbar appearance */
  box-sizing: border-box; /* Ensure padding is included in the height calculation */
}
/* 로그인 입력 */
.form-control-home {
  padding: 10px 0px 10px 16px;
  border-radius: 2em;
  overflow: hidden; /* Prevent the default scrollbar appearance */
  box-sizing: border-box; /* Ensure padding is included in the height calculation */
}
.form-control::placeholder {
  color: #fff;
  opacity: 1; /* Ensure the placeholder text is fully opaque */
}

.loading-overlay {
  position: fixed; /* Fixed position to stay on the screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  z-index: 9999; /* Ensure it stays on top */
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: 0.4em;
}


/* .input-container {
  position: relative;
  width: 100%;
} */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column; /* Allows thumbnails to appear above the textarea */
  align-items: stretch;
}

.form-control.custom-input-bg {
  /*padding-left: 50px; */
  margin-top: 2px;
  padding-left: 60px; /* Increase left padding to add space between placeholder and upload button */
  padding-right: 50px; /* Add padding to avoid overlapping with the button */
  resize: none; /* Disable resize */
  /*overflow-y: auto; /* Allow scrolling inside textarea if needed */
  box-sizing: border-box; /* 패딩이 포함된 높이 계산 */

  /*overflow-y: auto; /* Allow vertical scrolling inside textarea if needed */
  /*box-sizing: border-box; /* 패딩이 포함된 높이 계산 */
  max-height: calc(1.5em * 5 + 32px); /* 최대 높이 설정 (5줄 + padding 포함) */
  line-height: 1.5em; /* 줄 간격 설정 */
}

.text-input-area {
  border-radius: 0;
  left: 55px;
  resize:'none';
}

/* 제출 버튼 스타일 */
.btn-inside-textarea {
  position: absolute;
  right: 10px;
  bottom: 43px;
  /*z-index: 5; */
  border-radius: 2em; /* 50% */
  color: #343a40;
  background-color: #e2e3e5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  padding: 0;
  cursor: pointer;
}

/* file upload 버튼 */
.btn-file-upload {
  position: absolute;
  left: 10px;
  bottom: 43px;
  /* z-index: 5; */
  border-radius: 2em;
  color: #343a40;
  background-color: #e2e3e5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  padding: 0;
  cursor: pointer;
}


/* .btn-inside-textarea i,
.btn-file-upload i {
  -webkit-text-stroke: 1px #343a40;
  color: #ffffff;
} */

.btn-inside-textarea i,
.btn-file-upload i {
  font-size: 18px; /* Adjust the icon size for better visibility */
  color: #343a40; /* Use a contrasting color to improve visibility */
}

/* 썸네일 컨테이너 스타일 */
.file-thumbnails {
  display: flex;
  gap: 10px;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Ensure thumbnails are in one line */
  margin-top: 32px;
  padding: 5px 0;
  border-top: 1px solid #ddd; /* 구분선 추가 */
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.file-thumbnails::-webkit-scrollbar {
  height: 6px; /* Scrollbar height */
}

.file-thumbnails::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 4px;
}

.file-thumbnails::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Scrollbar thumb color on hover */
}

/* 개별 썸네일 스타일 */
.file-thumbnail {
  position: relative; /* Position relative to place the remove button */
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  color: #343a40;
  white-space: nowrap;
}

.file-thumbnail i {
  color: #007bff; /* Blue color for the file icon */
}


.file-remove-button {
  position: absolute;
  top: -5px; /* Adjust to position the button inside the thumbnail */
  right: -5px; /* Adjust to position the button inside the thumbnail */
  width: 18px;
  height: 18px;
  background-color:  #9ea8b3; /*#6c757d; /*#ff4d4f;*/
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  line-height: 1; /* Center the '×' inside the button */
}

.file-remove-button:hover {
  background-color:  #7b90ac; /*#5a6268; /* #e63946; */
}
.settings-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .settings-modal {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 400px; /* Adjust the width as needed */
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .settings-header .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  